const _jh= {};
(function() {
	Object.assign(_jh, {
		each: function( obj, callback ) {
			/*Array.prototype.some.call(target, function(v, i) {
				callback.call(v, i, v);
			});*/
			var length, i = 0;

			if ( this.isArrayLike( obj ) ) {
				length = obj.length;
				for ( ; i < length; i++ ) {
					if ( callback.call( obj[ i ], i, obj[ i ] ) === false ) {
						break;
					}
				}
			} else {
				for ( i in obj ) {
					if ( callback.call( obj[ i ], i, obj[ i ] ) === false ) {
						break;
					}
				}
			}

			return obj;
		},
		isArrayLike: function ( obj ) {

			// Support: real iOS 8.2 only (not reproducible in simulator)
			// `in` check used to prevent JIT error (gh-2145)
			// hasOwn isn't used here due to false negatives
			// regarding Nodelist length in IE
			var length = !!obj && "length" in obj && obj.length,
				type = typeof obj;

			return type === "array" || length === 0 ||
				typeof length === "number" && length > 0 && ( length - 1 ) in obj;
		},
		copyObj: function (obj) {
	  		var copy = {}, attr;
		  	if (typeof obj === 'object' && obj !== null) {
		  		if( "length" in obj ) copy=[];
		    	for ( attr in obj ) {
		      		if (obj.hasOwnProperty(attr)) {
		        		copy[attr] = obj[attr];
		      		}
		    	}
		  	} else {
		    	copy = obj;
		  	}
		  	return copy;
		},
		on: function( type, obj, callback ) {
			if( obj.length ) {
				Object.keys(obj).forEach(function(v,i) {
					obj[v].addEventListener( type, callback );
				});
			} else {
				obj.addEventListener( type, callback );
			}
		}
	});
	
})();
export default _jh;