gmapObj= {};
initMap= {};

// google.maps.event.addDomListener(window, 'load', initMap);

!function(){
	var lang= document.documentElement.lang;
	if( !window.google || !window.google.maps ) {
	  var script = document.createElement('script');
	    script.type = 'text/javascript';
	    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0Iyr7q74ujx74IcvzEg9g4jICyFehogM&callback=initMap&language='+lang;
    document.body.appendChild(script);
	}
	else {
		window.google.maps.event.clearInstanceListeners(window,'tilesloaded');
		initMap();
	}

}();
gmapObj.option = {
	center: { lat: 37.550413, lng: 127.184724 },
	// scrollwheel: true,
	zoom: 17,
	gestureHandling: 'cooperative'
}
!function() {

	initMap= function () {
		var varInt= (window.innerWidth/500000).toFixed(3),
			centerY= gmapObj.option.center.lat,
			centerX= gmapObj.option.center.lng,
			centeLng= window.innerWidth > 800 ? centerX - varInt : centerX;
		// window.google.maps.event.clearListeners(window);
		// window.google.maps.event.clearListeners(document);
		var title= 'STX바이오',
			myLatLng = {lat: centerY, lng: centerX};

	    
	  // Create a map object and specify the DOM element for display.
	  gmapObj.gmap = new google.maps.Map(document.getElementById('g-map'), gmapObj.option);

	  // Create a marker and set its position.
		gmapObj.markerOpt= {
			map: gmapObj.gmap,
	  	position: myLatLng,
	  	labelClass: "labels",
	  	title: title,
	  	icon: {
				url: '/dist/img/icon/location.svg',
				// size: new google.maps.Size(96, 96),
				scaledSize: new google.maps.Size(72, 72),
				// anchor: new google.maps.Point(36, 72)
			},
			// position: myLatLng,
			// animation:google.maps.Animation.BOUNCE
		}
	                
	  var marker = new google.maps.Marker(gmapObj.markerOpt);
		
		gmapObj.markerOpt2= Object.assign({}, gmapObj.markerOpt);
		gmapObj.markerOpt2.icon= {
			url: '/dist/img/logo/logo.svg',
			// size: new google.maps.Size(196, 41.81333333),
			scaledSize: new google.maps.Size(128, 48),
			anchor: new google.maps.Point(64, -2)
		}	
	  var marker2 = new google.maps.Marker(gmapObj.markerOpt2);
	}

}();